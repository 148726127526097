"use client";

interface ScrollButtonProps {
  targetSectionId: string;
}

export default function ScrollButton({ targetSectionId }: ScrollButtonProps) {
  return (
    <div className="absolute left-1/2 -translate-x-1/2 transform">
      <button
        onClick={() => {
          document
            .getElementById(targetSectionId)
            ?.scrollIntoView({ behavior: "smooth" });
        }}
        className="flex h-[72px] w-[72px] items-center justify-center rounded-full bg-white transition-all hover:translate-y-1 hover:shadow-md"
        aria-label={`Scroll to ${targetSectionId} section`}
      >
        <i className="fa-regular fa-arrow-down text-2xl text-primary" />
      </button>
    </div>
  );
}
