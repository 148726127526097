"use client";

import { useState, useEffect, useCallback } from "react";
import { cn } from "~/utils/cn";
import type {
  CarouselProps,
  CarouselItem,
  CarouselImageItem,
} from "./carousel.types";
import Image from "next/image";

const CarouselArrow = ({
  direction,
  onClick,
  className,
  icon,
}: {
  direction: "prev" | "next";
  onClick: () => void;
  className: string;
  icon: string;
}) => (
  <button
    onClick={onClick}
    data-testid={`carousel-desktop-${direction}`}
    className={cn(
      "items-center justify-center rounded-full shadow-lg transition-colors",
      "absolute top-1/2 z-20 hidden -translate-y-1/2 lg:flex",
      "hover:bg-surface-brand-hover",
      "hover:hover:border-brand-primary-rest",
      "active:bg-button-brand-primary-light-pressed",
      "active:active:border-brand-primary-rest",
      direction === "next" ? "right-0" : "left-0",
      className,
    )}
    aria-label={`${direction === "prev" ? "Previous" : "Next"} slide`}
  >
    <i className={icon} aria-hidden="true" />
  </button>
);

const CAROUSEL_DEFAULTS = {
  DEFAULT_VALUE: 0,
  INTERVAL: 5000,
  WIDTH: "w-full",
  ITEMS_PER_VIEW: 1,
} as const;

export const Carousel = ({
  items,
  defaultValue = CAROUSEL_DEFAULTS.DEFAULT_VALUE,
  value,
  onChange,
  continuous = true,
  interval = CAROUSEL_DEFAULTS.INTERVAL,
  autoplay = true,
  width = CAROUSEL_DEFAULTS.WIDTH,
  showArrows = true,
  arrowsOutside = false,
  showDelimiters = true,
  itemsPerView = CAROUSEL_DEFAULTS.ITEMS_PER_VIEW,
  className,
  arrowClassName = "bg-button-brand-primary-dark-rest hover:bg-button-brand-primary-dark-hover active:bg-button-brand-primary-dark-pressed text-white h-[42px] w-[42px] lg:h-[30px] lg:w-[30px]",
  delimiterClassName = "bg-button-brand-primary-dark-rest",
  prevArrowIcon = "fa-solid fa-arrow-left fa-xs",
  nextArrowIcon = "fa-solid fa-arrow-right fa-xs",
  containerClassName,
}: CarouselProps) => {
  // Group related calculations
  const carouselMetrics = {
    totalGroups: Math.ceil(items.length / itemsPerView),
    initialGroupIndex: Math.floor(defaultValue / itemsPerView),
  };

  const [currentIndex, setCurrentIndex] = useState(
    carouselMetrics.initialGroupIndex,
  );
  const [isAutoPlaying, setIsAutoPlaying] = useState(autoplay);
  const activeIndex =
    value !== undefined ? Math.floor(value / itemsPerView) : currentIndex;

  // Navigation handlers
  const updateIndex = useCallback(
    (newIndex: number) => {
      if (!value) {
        setCurrentIndex(newIndex);
      }
      onChange?.(newIndex * itemsPerView);
    },
    [value, onChange, itemsPerView],
  );

  const next = useCallback(() => {
    if (continuous || currentIndex < carouselMetrics.totalGroups - 1) {
      updateIndex(
        currentIndex === carouselMetrics.totalGroups - 1 ? 0 : currentIndex + 1,
      );
    }
  }, [continuous, currentIndex, carouselMetrics.totalGroups, updateIndex]);

  const prev = useCallback(() => {
    if (continuous || currentIndex > 0) {
      updateIndex(
        currentIndex === 0 ? carouselMetrics.totalGroups - 1 : currentIndex - 1,
      );
    }
  }, [continuous, currentIndex, carouselMetrics.totalGroups, updateIndex]);

  // Autoplay handling
  useEffect(() => {
    if (!isAutoPlaying) return;
    const intervalId = setInterval(next, interval);
    return () => clearInterval(intervalId);
  }, [isAutoPlaying, interval, next]);

  const handleMouseEnter = useCallback(() => setIsAutoPlaying(false), []);
  const handleMouseLeave = useCallback(
    () => setIsAutoPlaying(autoplay),
    [autoplay],
  );

  // Render helpers
  const renderSlideContent = useCallback(
    (item: CarouselItem | CarouselImageItem) => {
      if ("src" in item) {
        return (
          <div className="relative h-full w-full overflow-hidden rounded-xl">
            <Image
              src={item.src}
              alt={item.alt}
              className="h-full w-full bg-white object-contain"
              width={1200}
              height={1200}
              priority={true}
              quality={100}
            />
          </div>
        );
      }
      return item.content;
    },
    [],
  );

  // Render carousel items
  const renderCarouselItems = useCallback(
    () =>
      Array.from({ length: carouselMetrics.totalGroups }, (_, groupIndex) => (
        <div key={groupIndex} className="w-full shrink-0">
          <div
            key={`inner-${groupIndex}`}
            className="flex flex-col gap-2 lg:flex-row lg:gap-4"
          >
            {items
              .slice(groupIndex * itemsPerView, (groupIndex + 1) * itemsPerView)
              .map(item => (
                <div key={item.id}   className={cn(
                  "w-full",
                  // Only apply w-1/2 if there's more than one item per view
                    itemsPerView > 1 && "lg:w-1/2",
                  )}
                >
                  {renderSlideContent(item)}
                </div>
              ))}
          </div>
        </div>
      )),
    [items, itemsPerView, renderSlideContent, carouselMetrics.totalGroups],
  );

  return (
    <div
      className={cn("relative", width, className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      aria-label="carousel"
      role="region"
    >
      {showArrows && arrowsOutside && (
        <>
          <CarouselArrow
            direction="prev"
            onClick={prev}
            className={cn(
              "absolute top-1/2 z-20 hidden -translate-y-1/2 lg:flex",
              "left-0",
              arrowClassName,
            )}
            icon={prevArrowIcon}
          />
          <CarouselArrow
            direction="next"
            onClick={next}
            className={cn(
              "absolute top-1/2 z-20 hidden -translate-y-1/2 lg:flex",
              "right-0",
              arrowClassName,
            )}
            icon={nextArrowIcon}
          />
        </>
      )}

      <div className={cn("relative", arrowsOutside && "lg:mx-12")}>
        {showArrows && !arrowsOutside && (
          <>
            <CarouselArrow
              direction="prev"
              onClick={prev}
              className={cn(
                "absolute top-1/2 z-20 hidden -translate-y-1/2 lg:flex",
                "left-4",
                arrowClassName,
              )}
              icon={prevArrowIcon}
            />
            <CarouselArrow
              direction="next"
              onClick={next}
              className={cn(
                "absolute top-1/2 z-20 hidden -translate-y-1/2 lg:flex",
                "right-4",
                arrowClassName,
              )}
              icon={nextArrowIcon}
            />
          </>
        )}

        <div
          className={cn(
            "relative overflow-hidden rounded-lg",
            "h-auto",
            containerClassName,
          )}
        >
          <div
            className="flex transition-transform duration-700 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {renderCarouselItems()}
          </div>
        </div>

        <div className="absolute bottom-0 left-1/2 -mb-12 -translate-x-1/2">
          {showDelimiters && (
            <div className="hidden space-x-2 lg:flex">
              {Array.from(
                { length: carouselMetrics.totalGroups },
                (_, index) => (
                  <button
                    key={index}
                    onClick={() => updateIndex(index)}
                    className={cn(
                      "h-2 w-2 rounded-full transition-colors",
                      index === activeIndex
                        ? delimiterClassName
                        : "bg-neutral-30",
                    )}
                    aria-label={`Go to slides ${index * itemsPerView + 1}-${Math.min((index + 1) * itemsPerView, items.length)}`}
                  />
                ),
              )}
            </div>
          )}

          {showArrows && (
            <div className="absolute -bottom-5 left-1/2 flex -translate-x-1/2 space-x-4 lg:hidden">
              <button
                onClick={prev}
                data-testid="carousel-desktop-prev"
                className={cn(
                  "flex items-center justify-center rounded-full shadow-lg transition-colors",
                  "h-[42px] w-[42px]",
                  arrowClassName,
                )}
                aria-label="Previous slide"
              >
                <i className={prevArrowIcon} aria-hidden="true" />
              </button>
              <button
                onClick={next}
                data-testid="carousel-desktop-next"
                className={cn(
                  "flex items-center justify-center rounded-full shadow-lg transition-colors",
                  "h-[42px] w-[42px]",
                  arrowClassName,
                )}
                aria-label="Next slide"
              >
                <i className={nextArrowIcon} aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
