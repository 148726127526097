"use client";

import Image from "next/image";
import { useScroll, motion, useTransform } from "framer-motion";
import { useRef } from "react";
import FindJobsButton from "../../button/FindJobsButton";
import FindTalentButton from "../../button/FindTalentButton";

export default function Hero() {
  const ref = useRef(null);
  const { scrollY } = useScroll();

  const backgroundY = useTransform(scrollY, [0, 500], [0, 150]);
  const middleY = useTransform(scrollY, [0, 500], [0, 100]);
  const contentY = useTransform(scrollY, [0, 500], [0, 50]);

  return (
    <section
      ref={ref}
      className="relative flex h-[592px] w-full items-center justify-center overflow-hidden bg-brand-primary-light-00 lg:h-[800px]"
    >
      {/* Background Layer */}
      <motion.div
        style={{
          backgroundImage:
            'url("https://scalis-assets.s3.us-east-2.amazonaws.com/public/landing/bg_home.png")',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
          backgroundSize: "cover",
          marginTop: "55px",
          y: backgroundY,
        }}
        className="absolute inset-0 will-change-transform"
      />

      {/* Middle Layer */}
      <motion.div
        style={{ y: middleY }}
        className="to-brand-primary-light-00/50 absolute inset-0 bg-gradient-to-b from-transparent"
      />

      {/* Content Layer */}
      <motion.div
        style={{ y: contentY }}
        className="relative mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 lg:px-8"
      >
        <div className="flex flex-col items-center space-y-6 lg:space-y-20">
          {/* Hero Content */}
          <div className="flex flex-col items-center space-y-6 pt-10 lg:pt-20">
            <Image
              src="https://scalis-assets.s3.us-east-2.amazonaws.com/public/landing/scalis-ladder.svg"
              alt="Scalis Ladder"
              width={72}
              height={72}
              draggable={false}
              quality={100}
              className="h-12 w-12 lg:h-[72px] lg:w-[72px]"
            />

            <h1 className="max-w-[300px] text-5xl font-semibold leading-tight tracking-tight text-neutral-primary md:max-w-xl lg:max-w-2xl lg:!text-[72px]">
              Integrated. Intuitive. Infinite.
            </h1>

            <h2
              className="text-base font-medium leading-8 tracking-tight 
              text-neutral-secondary lg:!text-xl"
            >
              The First All-in-One Career Marketplace & Hiring Platform
            </h2>
          </div>

          {/* Buttons */}
          <div className="flex items-center gap-4">
            <FindJobsButton
              className="h-10 w-auto rounded-xl px-4 text-sm font-medium 
                tracking-tight lg:h-[72px] lg:w-[264px] lg:rounded-[18px] lg:px-5 lg:!text-2xl"
              iconClassName="h-4 w-4 lg:h-5 lg:w-5 -ml-2 lg:ml-0"
            />
            <FindTalentButton
              className="-mr-1 h-10 w-auto rounded-xl px-4 text-sm 
                tracking-tight lg:h-[72px] lg:w-[264px] lg:rounded-[18px] lg:px-5 lg:!text-2xl"
              iconClassName="h-6 w-6 lg:h-[30px] lg:w-[31px] -ml-1"
            />
          </div>
        </div>
      </motion.div>
    </section>
  );
}
