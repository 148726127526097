import Link from "next/link";
import { cn } from "~/utils/cn";
import { Routes } from "src/app/_constants/routes";
interface FindTalentButtonProps {
  className?: string;
  iconClassName?: string;
}

export default function FindTalentButton({
  className,
  iconClassName,
}: FindTalentButtonProps) {
  return (
    <Link href={Routes.landing.findTalent}>
      <button
        className={cn(
          "flex flex-row items-center justify-center",
          "h-[72px] w-[264px]",
          "px-2 py-5",
          "rounded-[18px]",
          "bg-brand-primary-rest text-white",

          // Hover state - Primary Button Hover
          "hover:bg-button-brand-primary-dark-hover",
          "hover:border-4 hover:border-brand-primary-light-hover",

          // Pressed state - Primary Button Pressed
          "active:bg-button-brand-primary-dark-pressed",
          "active:border-4 active:border-brand-primary-light-pressed",

          className,
        )}
      >
        Find Talent
        <span className="ml-2">
          <svg
            className={cn("h-[30px] w-[31px] fill-current", iconClassName)}
            viewBox="0 0 31 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18.625 10C18.625 8.90625 18 7.89062 17.0625 7.30469C16.0859 6.75781 14.875 6.75781 13.9375 7.30469C12.9609 7.89062 12.375 8.90625 12.375 10C12.375 11.1328 12.9609 12.1484 13.9375 12.7344C14.875 13.2812 16.0859 13.2812 17.0625 12.7344C18 12.1484 18.625 11.1328 18.625 10ZM10.5 10C10.5 8.24219 11.4375 6.60156 13 5.70312C14.5234 4.80469 16.4375 4.80469 18 5.70312C19.5234 6.60156 20.5 8.24219 20.5 10C20.5 11.7969 19.5234 13.4375 18 14.3359C16.4375 15.2344 14.5234 15.2344 13 14.3359C11.4375 13.4375 10.5 11.7969 10.5 10ZM8.66406 23.125H22.2969C21.9453 20.6641 19.8359 18.75 17.2578 18.75H13.7031C11.125 18.75 9.01562 20.6641 8.66406 23.125ZM6.75 23.8672C6.75 20 9.83594 16.875 13.7031 16.875H17.2578C21.125 16.875 24.25 20 24.25 23.8672C24.25 24.4922 23.7031 25 23.0781 25H7.88281C7.25781 25 6.75 24.4922 6.75 23.8672Z" />
          </svg>
        </span>
      </button>
    </Link>
  );
}
